/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { fetchBalance } from '@wagmi/core'
import '../App.css'
import TokenAbi from '../config/TokenAbi.json'
import PresaleAbi from '../config/PresaleAbi.json'
import '../styles/PresaleContainer.css'
import Input from '../components/Input.tsx'
// import Footer from "../container/Footer.jsx";
import { readContract, writeContract } from '@wagmi/core'
import ClipLoader from 'react-spinners/ClipLoader'
import { waitForTransaction } from '@wagmi/core'
import bnb from '../icons/bnb.svg'
import eth from '../icons/eth.png'
import usdc from '../icons/usdc.png'
import usdt from '../icons/USDT.svg'
import BEE from '../icons/TokenLogo.png'
import BeeHeader from '../icons/TokenLogo.png'
import Web3 from 'web3'
import { useWeb3Modal } from '@web3modal/react'
import styles from '../styles/container/Container.module.scss'
import { toast } from 'react-hot-toast'

function CountDownComponent() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = () => {
    const utcNow = Date.now();
    // futureDate defines the date and time the countdown will taget, set it as needed
    // It will never go negative, and if countdown finished, it will stop at zero
    const futureDate = new Date("2024-04-20 17:00:00 UTC");
    const difference = utcNow - futureDate.getTime();

    var days = 0;
    var hours = 0;
    var minutes = 0;
    var seconds = 0;
    if (difference < 0) {
      days = Math.abs(Math.floor(difference / (1000 * 60 * 60 * 24) + 1));
      hours = Math.abs(
        Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + 1)
      );
      minutes = Math.abs(
        Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60) + 1)
      );
      seconds = Math.abs(Math.floor((difference % (1000 * 60)) / 1000 + 1));
    }

    setTimeLeft({ days, hours, minutes, seconds });
  };

  useEffect(() => {
    calculateTimeLeft();
    const interval = setInterval(() => calculateTimeLeft(), 1000);
    return () => clearInterval(interval);
  }, []);

  // Uncomment .padStart(2, "0") to add a leading zero to a single digit,
  // so for example it would show "06" instead of "6"
  const formattedDays = timeLeft.days.toString(); //.padStart(2, "0");
  const formattedHours = timeLeft.hours.toString(); //.padStart(2, "0");
  const formattedMinutes = timeLeft.minutes.toString(); //.padStart(2, "0");
  const formattedSeconds = timeLeft.seconds.toString(); //.padStart(2, "0");

  return (
    <>
      <span className="countdownBigText">{formattedDays}</span>
      <span className="countdownSmallText">days</span>
      <span className="countdownBigText">{formattedHours}</span>
      <span className="countdownSmallText">hours</span>
      <span className="countdownBigText">{formattedMinutes}</span>
      <span className="countdownSmallText">minutes</span>
      <span className="countdownBigText">{formattedSeconds}</span>
      <span className="countdownSmallText">seconds</span>
    </>
  );
}


const BNB_PROVIDER_URL = 'https://bsc-rpc.publicnode.com'
const BASE_PROVIDER_URL = 'https://base.meowrpc.com'
const ARB_PROVIDER_URL = 'https://arbitrum-one-rpc.publicnode.com'
let BNBWeb3 = new Web3(new Web3.providers.HttpProvider(BNB_PROVIDER_URL))
let BaseWeb3 = new Web3(new Web3.providers.HttpProvider(BASE_PROVIDER_URL))
let ArbWeb3 = new Web3(new Web3.providers.HttpProvider(ARB_PROVIDER_URL))
const BnbPresaleAddress = '0xdca00e68f7d790d81e009bc8b57b0878dba9ce86'
const BnbUsdcAddress = '0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2'
const BnbUsdtAddress = '0x55d398326f99059fF775485246999027B3197955'
const BasePresaleAddress = '0x90ed79b20Ac019C7EA8a143532C29d5651984506'
const BaseUsdcAddress = '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
const ArbPresaleAddress = '0x90ed79b20Ac019C7EA8a143532C29d5651984506'
const ArbUsdcAddress = '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'
const ArbUsdtAddress = '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'


const App = () => {
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const [tokenAmount, setAmount] = useState(0)
  const [allowanceAmount, setAllowanceAmount] = useState(0)
  const [raisedAmount, setRaisedAmount] = useState(0)
  let [loading, setLoading] = useState(false)
  let [confirming, setConfirming] = useState(false)

  const [PresaleAddress, setPresaleAddress] = useState()
  const [usdcAddress, setUsdcAddress] = useState()
  const [usdtAddress, setUsdtAddress] = useState()

  const [mainLogo, setMainLogo] = useState(bnb)
  const [usdcDecimal, setUsdcDecimal] = useState()

  const [mainWeb3, setMainWeb3] = useState(BNBWeb3)
  const [selectedToken, setSelectToken] = useState(0)
  const [selectedTokenName, setSelectedTokenName] = useState()
  const [outTokenAmount, setOutTokenAmount] = useState(0)
  let [accountBalance, setAccountBalance] = useState(0)
  const [firstConnect, setFirstConnect] = useState(false);
  const [progress, setProgress] = useState('0%')
  const { open } = useWeb3Modal()
  const [presaleId, setPresaleId] = useState(null)
  const [totalAmount, setTotalAmount] = useState(0)
  const [tokenSoldAmount, setTokenSoldAmount] = useState(0)
  const [totalToknesToSell, setTotalTokensToSell] = useState(0)
  const [presaleStatus, setPresaleStatus] = useState('null')
  const [claimable, setClaimable] = useState(false)
  const [claimableAmount, setClaimableAmount] = useState(0)
  const onConnect = async () => {
    await open()
  }

  const onConnectWallet = async () => {
    await open();
    setFirstConnect(true);
  };

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload();
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true)
      reloadWindow();

  }, [isConnected, firstConnect])

  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(56)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (
        chain.id !== 56 &&
        chain?.id !== 8453 &&
        chain?.id !== 42161
      )
        switchChain()
    }
  }, [isConnected, chain?.id, switchNetwork])

  useEffect(() => {
    const setChainData = async () => {
      try {
        if (chain?.id === 56) {
          setMainWeb3(BNBWeb3)
          setPresaleAddress(BnbPresaleAddress)
          setUsdcAddress(BnbUsdcAddress)
          setUsdtAddress(BnbUsdtAddress)
          setMainLogo(bnb)
          setUsdcDecimal(18)
        } else if (chain?.id === 8453) {
          setMainWeb3(BaseWeb3)
          setPresaleAddress(BasePresaleAddress)
          setUsdcAddress(BaseUsdcAddress)
          setMainLogo(eth)
          setUsdcDecimal(6)
        } else if (chain?.id === 42161) {
          setMainWeb3(ArbWeb3)
          setPresaleAddress(ArbPresaleAddress)
          setUsdcAddress(ArbUsdcAddress)
          setUsdtAddress(ArbUsdtAddress)
          setMainLogo(eth)
          setUsdcDecimal(6)
        }
      } catch (e) {
        console.error(e)
      }
    }
    if (
      chain?.id === 56 ||
      chain?.id === 8453 ||
      chain?.id === 42161
    ) {
      setChainData()
    }
  }, [chain?.id])

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  const onTokenAllowance = async () => {
    try {
      setConfirming(true)
      let approve
      if (selectedToken === 1) {
        approve = await writeContract({ address: usdcAddress, abi: TokenAbi, functionName: 'approve', args: [PresaleAddress, mainWeb3.utils.toBN(String(tokenAmount * (10 ** usdcDecimal)))] })
      } else if (selectedToken === 2) {
        approve = await writeContract({ address: usdtAddress, abi: TokenAbi, functionName: 'approve', args: [PresaleAddress, mainWeb3.utils.toBN(String(tokenAmount * (10 ** usdcDecimal)))] })
        toast.success('Successfully approved');
      }
      const approveData = await waitForTransaction({
        hash: approve.hash
      })
      console.log('approveData', approveData)
      setConfirming(false)
      let allowance
      if (selectedToken === 1) {
        allowance = await readContract({ address: usdcAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
      } else if (selectedToken === 2) {
        allowance = await readContract({ address: usdtAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
      }
      setAllowanceAmount(Number(allowance) / 10 ** usdcDecimal)
    } catch (err) {
      toast.error(err.details)
      setConfirming(false)
    }
  }

  const onTokenDeposit = async () => {
    try {
      setConfirming(true)
      let deposit
      if (selectedToken === 0) {
        if (chain?.id === 56) {
          deposit = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'buyWithBNB', value: mainWeb3.utils.toWei(String(tokenAmount), 'ether') })
        } else {
          deposit = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'buyWithETH', value: mainWeb3.utils.toWei(String(tokenAmount), 'ether') })
        }
      } else if (selectedToken === 1) {
        deposit = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'buyWithUSDC', args: [mainWeb3.utils.toBN(String(tokenAmount * (10 ** usdcDecimal)))] })
      } else {
        deposit = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'buyWithUSDT', args: [mainWeb3.utils.toBN(String(tokenAmount * (10 ** usdcDecimal)))] })
      }
      const depositData = await waitForTransaction({
        hash: deposit.hash
      })
      console.log('depositData', depositData)
      setTimeout(function () {
        setConfirming(false)
      }, 3000)
      toast.success(
        `Successfully ${tokenAmount} ${selectedTokenName} deposited`
      )
      const presaleData = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'presale', args: [presaleId] });
      const Amount = Number(presaleData[7])
      setRaisedAmount(Amount)
      const percent = (Number(raisedAmount) * 100) / totalAmount
      const prog = percent.toFixed(3) + '%'
      setProgress(prog)
    } catch (err) {
      toast.error(
        'There is a problem with your deposit. Please try again later'
      )
      setConfirming(false)
    }
  }

  const onTokenClaim = async () => {
    try {
      setConfirming(true)
      let claim;
      claim = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'claimAmount', args: [presaleId] })
      const claimData = await waitForTransaction({
        hash: claim.hash
      })
      console.log('claimData', claimData)
      setTimeout(function () {
        setConfirming(false)
      }, 3000)
      toast.success(`Successfully claimed`)
      const claimableData = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'userClaimData', args: [address, presaleId] });
      let claimableAmounts = ethers.utils.formatEther(
        claimableData[2] - claimableData[3] - claimableData[4]
      )
      setClaimableAmount(claimableAmounts)
    } catch (err) {
      toast.error('There is a problem with your claim. Please try again later')
      setConfirming(false)
    }
  }

  const setMaxAmount = async () => {
    if (selectedToken === 0 && accountBalance > 0)
      accountBalance = accountBalance - 0.01
    setAmount(accountBalance)
  }

  const ETHSelect = async () => {
    try {
      setSelectToken(0)
    } catch (err) { }
  }

  const USDCSelect = async () => {
    try {
      setSelectToken(1)
    } catch (err) { }
  }

  const USDTSelect = async () => {
    try {
      setSelectToken(2)
    } catch (err) { }
  }

  useEffect(() => {
    const FetchData = async () => {
      try {
        if (address) {
          if (selectedToken === 0) {
            if (chain?.id === 56) {
              setSelectedTokenName('BNB')
            } else {
              setSelectedTokenName('ETH')
            }
            accountBalance = await fetchBalance({ address: address })
            setAccountBalance(accountBalance.formatted)
          } else if (selectedToken === 1) {
            setSelectedTokenName("USDC");
            const balance = await readContract({ address: usdcAddress, abi: TokenAbi, functionName: 'balanceOf', args: [address] });
            setAccountBalance(Number(balance) / (10 ** usdcDecimal));
          } else {
            setSelectedTokenName("USDT");
            const balance = await readContract({ address: usdtAddress, abi: TokenAbi, functionName: 'balanceOf', args: [address] });
            setAccountBalance(Number(balance) / (10 ** usdcDecimal));
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    const FetchGlobalData = async () => {
      try {
        const Id = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'presaleId' });
        setPresaleId(Id);
        const presaleData = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'presale', args: [Id] });
        setRaisedAmount(Number(presaleData[7]))
        setTotalAmount(Number(presaleData[6]))
        setTokenSoldAmount(Number(presaleData[4]))
        setTotalTokensToSell(Number(presaleData[5]))
        setPresaleStatus(presaleData[8])
        setClaimable(presaleData[9])
        if (presaleData[9] === true) {
          const claimableData = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'userClaimData', args: [address, Id] });
          let claimableAmounts = ethers.utils.formatEther(
            claimableData[2] - claimableData[3] - claimableData[4]
          )
          setClaimableAmount(claimableAmounts)
        }
        FetchData()
      } catch (e) {
        console.error(e)
      }
    }
    if (presaleId == null && PresaleAddress !== undefined) {
      FetchGlobalData();
    } else {
      FetchData();
    }
  }, [selectedToken, address, PresaleAddress, chain?.id])

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const percent = (Number(raisedAmount) * 100) / totalAmount
        const prog = percent.toFixed(3) + '%'
        setProgress(prog)
      } catch (e) {
        console.error(e)
      }
    }
    if (Number(raisedAmount) > 0) {
      fetchProgress()
    }
  }, [raisedAmount, totalAmount])

  useEffect(() => {
    const FetchData = async () => {
      try {
        let balance
        if (selectedToken === 0) {
          if (chain?.id === 56) {
            balance = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'bnbToTokens', args: [presaleId, mainWeb3.utils.toWei(String(tokenAmount), 'ether')] });
          } else {
            balance = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'ethToTokens', args: [presaleId, mainWeb3.utils.toWei(String(tokenAmount), 'ether')] });
          }
        } else if (selectedToken === 1) {
          balance = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'usdtToTokens', args: [presaleId, mainWeb3.utils.toBN(String(tokenAmount * (10 ** usdcDecimal)))] });
        } else {
          balance = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'usdtToTokens', args: [presaleId, mainWeb3.utils.toBN(String(tokenAmount * (10 ** usdcDecimal)))] });
        }
        setOutTokenAmount((Number(balance) / 10 ** 18).toFixed(0))
      } catch (e) {
        console.error(e)
      }
    }
    if (tokenAmount > 0) { FetchData() }
    else {
      setOutTokenAmount(0)
    }
  }, [
    PresaleAddress,
    mainWeb3.utils,
    presaleId,
    selectedToken,
    tokenAmount,
    usdcDecimal
  ])

  useEffect(() => {
    const useTokenCheck = async () => {
      try {
        let allowance
        if (selectedToken === 1) {
          allowance = await readContract({ address: usdcAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
        } else if (selectedToken === 2) {
          allowance = await readContract({ address: usdtAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
        }
        setAllowanceAmount(Number(allowance) / 10 ** usdcDecimal)
      } catch (e) {
        console.error(e)
      }
    }
    if (tokenAmount > 0) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useTokenCheck()
    }
  }, [
    address,
    selectedToken,
    tokenAmount,
    PresaleAddress,
    usdcAddress,
    usdcDecimal,
    usdtAddress
  ])


  return (
    <div>
      <div className="GlobalContainer">
        {
          <>
            <section className="countdownHeader">
              <span className="countdownDescription">Final Chance: Buy $BEE before the Launch:</span>
              <CountDownComponent />
            </section>
            <div className="navBar">
              <img src={BeeHeader} alt="logo" className="tokenHeader" />
              <a className="navLink" href="#whitepaper">
                Whitepaper
              </a>
              <a className="navLink" href="#faq">
                FAQ
              </a>
              <div className="ButtonContainer">
                <div className="navConnectButtonBox">
                  {!isConnected ? (
                    <>
                      <button
                        className="navConnectButton"
                        type="submit"
                        onClick={() => {
                          onConnect()
                        }}
                      >
                        Connect<span class="navWallet"> Wallet</span>
                      </button>
                    </>
                  ) : (
                    <section className={styles.ConnectWalletSection}>
                      <div className="ChainGroupButton">
                        {chain?.id === 56 || chain?.id === 8453 || chain?.id === 42161 ? (
                          <button
                            className="navConnectButton"
                            type="submit"
                            onClick={() => onConnect()}
                          >
                            {address.slice(0, 4) + '...' + address.slice(-4)}
                          </button>
                        ) : (
                          <button
                            className="navConnectButton"
                            type="submit"
                            onClick={() => switchNetwork?.(56)}
                          >
                            {'BSC'}
                            {isLoading && pendingChainId === 56 && ''}
                          </button>
                        )}
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
            <div className="MainDashboard ResponsiveFlexLayout">
              <section className="LeftColumn">
                <h2 className="h2">
                  FAST-GROWING AI REVEALS NEW TOKEN WITH SHOCKING REWARD
                  SYSTEM
                </h2>
                <h1 className="h1">
                  $BEE IS THE FIRST TOKEN WORLDWIDE PROVIDING DAILY REWARDS TO
                  HOLDERS BASED ON ITS AI'S PERFORMANCE
                </h1>
                <p className="text">
                  Start earning today and receive up to 10,000 USDT in daily
                  passive staking income from a licensed and regulated global
                  AI and Betting Platform, no matter the market conditions.
                </p>
                <iframe
                  className="YouTube"
                  src="https://www.youtube.com/embed/1jzROE6EhxM"
                  title="What is Solana? SOL Explained with Animations"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </section>
              <section className="ContactBox RightColumn">
                <>
                  <div className="StyledContainer">
                    <section className="ContractContainer">
                      <section className="DepositBoxHeader">
                        <p className="ContractContentTextTitle h1">
                          PRESALE | LIVE | STAGE 1
                        </p>
                      </section>
                      <div className="PresaleContainer">
                        <div className="PresaleProgressContainer">
                          <section className="HeaderContent">
                            <p>Raised</p>
                            <p>Total Amount</p>
                          </section>
                          <section className="HeaderContent2">
                            <p>
                              ${' '}
                              {raisedAmount > 0
                                ? Number(
                                  (
                                    Number(raisedAmount) /
                                    10 ** usdcDecimal
                                  ).toFixed(0)
                                ).toLocaleString()
                                : 0}
                            </p>
                            <p>
                              ${' '}
                              {totalAmount > 0
                                ? Number(
                                  (
                                    Number(totalAmount) /
                                    10 ** usdcDecimal
                                  ).toFixed(0)
                                ).toLocaleString()
                                : 0}
                            </p>
                          </section>
                          <section className="HeaderContent3">
                            <section
                              className="HeaderContent4"
                              style={{ width: progress }}
                            ></section>
                          </section>
                          <section className="HeaderContent5">
                            <span>
                              UNTIL SOLD OUT :{' '}
                              {Number(
                                (Number(tokenSoldAmount) / 10 ** 18).toFixed(0)
                              ).toLocaleString()}{' '}
                              /{' '}
                              {Number(
                                (Number(totalToknesToSell) / 10 ** 18).toFixed(
                                  0
                                )
                              ).toLocaleString()}{' '}
                              BEE
                            </span>
                          </section>
                          {Number(claimableAmount) > 0 ? (
                            <section className="HeaderContent5">
                              <span>
                                YOUR HOLD :{' '}
                                {Number(claimableAmount).toLocaleString()} BEE
                              </span>
                            </section>
                          ) : (
                            <></>
                          )}
                          <section className="HeaderContent6">
                            <section className="stageBox starting">
                              <p>Stage 1</p>
                              <span className="HeaderContent7">
                                1 BEE= $ 0.005
                              </span>
                            </section>
                            <section className="stageBox unstarted">
                              <p>Stage 2</p>
                              <span className="HeaderContent7">
                                1 BEE = $ 0.02{' '}
                              </span>
                            </section>
                            <section className="stageBox unstarted">
                              <p>Stage 3</p>
                              <span className="HeaderContent7">
                                1 BEE = $ 0.03
                              </span>
                            </section>
                            <section className="stageBox unstarted">
                              <p>Stage 4</p>
                              <span className="HeaderContent7">
                                1 BEE= $ 0.05
                              </span>
                            </section>
                          </section>
                        </div>
                        <p class="one_how">1 BEE = $ 0.005</p>
                        {/* {Number(currentPrice) > 0 ? (1 / Number(currentPrice)).toFixed(3) : 0} */}
                        <section className="tokensButton">
                          {selectedToken === 0 ? (
                            <button
                              className="bnbButton tokenButtons selected"
                              onClick={ETHSelect}
                            >
                              <img className="tokenImage" src={mainLogo} />
                              <span className="tokenButtonText">{chain?.id === 56 ? 'BNB' : 'ETH'}</span>
                            </button>
                          ) : (
                            <button
                              className="bnbButton tokenButtons"
                              onClick={ETHSelect}
                            >
                              <img className="tokenImage" src={mainLogo} />
                              <span className="tokenButtonText">{chain?.id === 56 ? 'BNB' : 'ETH'}</span>
                            </button>
                          )}
                          {selectedToken === 1 ? (
                            <button
                              className="bnbButton tokenButtons selected"
                              onClick={USDCSelect}
                            >
                              <img className="tokenImage" src={usdc} />
                              <span className="tokenButtonText">
                                &nbsp;USDC
                              </span>
                            </button>
                          ) : (
                            <button
                              className="bnbButton tokenButtons"
                              onClick={USDCSelect}
                            >
                              <img className="tokenImage" src={usdc} />
                              <span className="tokenButtonText">
                                &nbsp;USDC
                              </span>
                            </button>
                          )}
                          {chain?.id !== 8453 ? (
                            selectedToken === 2 ? (
                              <button
                                className="bnbButton tokenButtons selected"
                                onClick={USDTSelect}
                              >
                                <img className="tokenImage" src={usdt} />
                                <span className="tokenButtonText">
                                  &nbsp;USDT
                                </span>
                              </button>
                            ) : (
                              <button
                                className="bnbButton tokenButtons"
                                onClick={USDTSelect}
                              >
                                <img className="tokenImage" src={usdt} />
                                <span className="tokenButtonText">
                                  &nbsp;USDT
                                </span>
                              </button>
                            )
                          ) : (
                            <></>
                          )}
                        </section>
                        <section className="BalancePart">
                          {selectedTokenName} Balance :{' '}
                          {Number(accountBalance) > 0.00001
                            ? Number(accountBalance).toFixed(3)
                            : '0'}
                        </section>
                        <>
                          {presaleStatus === true ? ( // true
                            <>
                              <section className="InputBox">
                                <section className="InputSection">
                                  <div className="LpBalance">
                                    <p className="Text1">You Pay : </p>
                                    <p
                                      onClick={setMaxAmount}
                                      className="MaxButton"
                                    >
                                      Max
                                    </p>
                                  </div>
                                  <section className="inputPanel">
                                    <section className="inputPanelHeader">
                                      <Input
                                        placeholder="Enter amount"
                                        label=""
                                        type="number"
                                        changeValue={setAmount}
                                        value={tokenAmount}
                                      />
                                      {selectedToken === 0 ? (
                                        <img
                                          className="tokenImage"
                                          src={mainLogo}
                                        />
                                      ) : selectedToken === 1 ? (
                                        <img
                                          className="tokenImage"
                                          src={usdc}
                                        />
                                      ) : (
                                        <img
                                          className="tokenImage"
                                          src={usdt}
                                        />
                                      )}
                                    </section>
                                  </section>
                                </section>
                                <section className="InputSection">
                                  <div className="LpBalance">
                                    <p className="Text1">Amount Of Tokens </p>
                                  </div>
                                  <section className="inputPanel">
                                    <section className="inputPanelHeader">
                                      <input
                                        placeholder="0"
                                        label=""
                                        type="number"
                                        value={outTokenAmount}
                                      />
                                      <img className="tokenImage" src={BEE} />
                                    </section>
                                  </section>
                                </section>
                              </section>
                              {Number(tokenAmount) > Number(allowanceAmount) &&
                                selectedToken !== 0 ? (
                                <section className="LockBox">
                                  {confirming === false ? (
                                    <>
                                      <button
                                        disabled={false}
                                        onClick={onTokenAllowance}
                                        className="PresaleButton"
                                      >
                                        Allow Tokens First
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <p className="Text1">Approving</p>
                                      <ClipLoader
                                        color={'#36d7b7'}
                                        loading={confirming}
                                        size={30}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                      />
                                    </>
                                  )}
                                </section>
                              ) : (
                                <>
                                  {
                                    <section className="LockBox">
                                      {confirming === false ? (
                                        <>
                                          {(isConnected && chain?.id === 56) ||
                                            chain?.id === 8453 ||
                                            chain?.id === 42161 ? (
                                            <>
                                              <button
                                                disabled={Number(tokenAmount) <= 0}
                                                onClick={onTokenDeposit}
                                                className="PresaleButton"
                                              >
                                                {Number(tokenAmount) <= 0 ? 'Please enter token amount' : 'Deposit Token Now!'}
                                              </button>
                                              {claimable === true &&
                                                claimableAmount > 0 ? (
                                                <button
                                                  disabled={false}
                                                  onClick={onTokenClaim}
                                                  className="PresaleButton"
                                                >
                                                  Claim (
                                                  {Number(
                                                    claimableAmount
                                                  ).toFixed(3)}{' '}
                                                  BEE)
                                                </button>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <p className="Text1">Confirming...</p>
                                          <ClipLoader
                                            color={'#36d7b7'}
                                            loading={confirming}
                                            size={30}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                          />
                                        </>
                                      )}
                                      {!isConnected ? (
                                        <>
                                          <button
                                            className="PresaleButton"
                                            type="submit"
                                            onClick={() => {
                                              onConnectWallet()
                                            }}
                                          >
                                            Connect Wallet
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {chain?.id === 56 ||
                                            chain?.id === 8453 ||
                                            chain?.id === 42161 ? (
                                            <></>
                                          ) : (
                                            <button
                                              className="PresaleButton"
                                              type="submit"
                                              onClick={() =>
                                                switchNetwork?.(56)
                                              }
                                            >
                                              {'Switch To BNB'}
                                              {isLoading &&
                                                pendingChainId === 56 &&
                                                ' (switching)'}
                                            </button>
                                          )}
                                        </>
                                      )}
                                    </section>
                                  }
                                </>
                              )}
                            </>
                          ) : presaleStatus === false ? (
                            <>
                              <p class="ContractContentTextTitle">
                                Presale is not live yet
                              </p>
                            </>
                          ) : (
                            <>
                              {!isConnected ?
                                <>
                                  {/* <button className="PresaleButton" type="submit" onClick={() => {
                                    onConnectWallet();
                                  }}>Connect Wallet</button> */}
                                </>
                                :
                                <>
                                  <p className='Text1'>Data Loading...</p>
                                  <ClipLoader
                                    color={'#36d7b7'}
                                    loading={confirming}
                                    size={30}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </>
                              }
                            </>
                          )}
                        </>
                        <div className="ButtonContainer">

                          {!isConnected ? (
                            <>
                              <div className='ChainGroupButton'>
                                <button
                                  className="ConnectButton" type="submit"
                                  onClick={() => onConnectWallet()}
                                >
                                  {'BSC'}
                                  {isLoading && pendingChainId === 56 && ''}
                                </button>
                                <button
                                  className="ConnectButton" type="submit"
                                  onClick={() => onConnectWallet()}
                                >
                                  {'Arbitrum'}
                                  {isLoading && pendingChainId === 42161 && ''}
                                </button>
                                <button
                                  className="ConnectButton" type="submit"
                                  onClick={() => onConnectWallet()}
                                >
                                  {'Base'}
                                  {isLoading && pendingChainId === 8453 && ''}
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              {chain?.id === 56 ?
                                <div className='ChainGroupButton'>
                                  <button
                                    className="ConnectButton" type="submit"
                                    onClick={() => switchNetwork?.(42161)}
                                  >
                                    {'Arbitrum'}
                                    {isLoading && pendingChainId === 42161 && ''}
                                  </button>
                                  <button
                                    className="ConnectButton" type="submit"
                                    onClick={() => switchNetwork?.(8453)}
                                  >
                                    {'Base'}
                                    {isLoading && pendingChainId === 8453 && ''}
                                  </button>
                                </div>
                                :
                                chain?.id === 42161 ?
                                  <div className='ChainGroupButton'>
                                    <button
                                      className="ConnectButton" type="submit"
                                      onClick={() => switchNetwork?.(56)}
                                    >
                                      {'BSC'}
                                      {isLoading && pendingChainId === 56 && ''}
                                    </button>
                                    <button
                                      className="ConnectButton" type="submit"
                                      onClick={() => switchNetwork?.(8453)}
                                    >
                                      {'Base'}
                                      {isLoading && pendingChainId === 8453 && ''}
                                    </button>
                                  </div>
                                  :
                                  chain?.id === 8453 ?
                                    <div className='ChainGroupButton'>
                                      <button
                                        className="ConnectButton" type="submit"
                                        onClick={() => switchNetwork?.(56)}
                                      >
                                        {'BSC'}
                                        {isLoading && pendingChainId === 56 && ''}
                                      </button>
                                      <button
                                        className="ConnectButton" type="submit"
                                        onClick={() => switchNetwork?.(42161)}
                                      >
                                        {'Arbitrum'}
                                        {isLoading && pendingChainId === 42161 && ''}
                                      </button>
                                    </div>
                                    :
                                    <div className='ChainGroupButton'>
                                      <button
                                        className="ConnectButton" type="submit"
                                        onClick={() => switchNetwork?.(56)}
                                      >
                                        {'BSC'}
                                        {isLoading && pendingChainId === 56 && ''}
                                      </button>
                                      <button
                                        className="ConnectButton" type="submit"
                                        onClick={() => switchNetwork?.(42161)}
                                      >
                                        {'Arbitrum'}
                                        {isLoading && pendingChainId === 42161 && ''}
                                      </button>
                                      <button
                                        className="ConnectButton" type="submit"
                                        onClick={() => switchNetwork?.(8453)}
                                      >
                                        {'Base'}
                                        {isLoading && pendingChainId === 8453 && ''}
                                      </button>
                                    </div>
                              }
                            </>
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                </>
              </section>
            </div>
          </>
        }
      </div>
      <section className="footer">
        <p className="footerText">Copyright 2024 | All rights reserved.</p>
      </section>
    </div>
  )
}

export default App
